/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import ClusterIllustration from "../assets/images/illustrations/home-cluster.svg";
import UI from "../common/UI";
import Container from "../common/Container";
import {H2} from "../common/typography/Headings";
import {Lead} from "../common/typography/Text";

const useStyles = createUseStyles({
    root: {
        backgroundColor: UI.colors.offWhite.toCSS(),
        overflow: "hidden",
        position: "relative",
        [UI.media.only("xs")]: {
            padding: "1.6rem 0 10rem"
        },
        [UI.media.gte("sm")]: {
            padding: "3.5rem 0 16rem"
        }
    },
    body: {
        display: "flex",
        [UI.media.only("xs")]: {
            flexDirection: "column"
        },
        [UI.media.gte("sm")]: {
            justifyContent: "flex-end",
            width: "100%"
        },
        [UI.media.gte("lg")]: {
            margin: "auto",
            width: "83.3333%"
        }
    },
    copy: {
        [UI.media.only("xs")]: {
            order: 1
        },
        [UI.media.gte("sm")]: {
            flexBasis: "66.666%"
        },
        [UI.media.gte("lg")]: {
            flexBasis: "50%"
        }
    },
    illustration: {
        position: "absolute",
        paddingRight: "3rem",
        [UI.media.only("xs")]: {
            bottom: 0,
            height: 156,
            left: 0,
            overflow: "hidden",
            width: "100%",
            "& img": {
                maxWidth: "120%"
            }
        },
        [UI.media.gte("sm")]: {
            right: "66.6666%",
            bottom: "-5rem"
        },
        [UI.media.gte("lg")]: {
            right: "50%"
        }
    }
});

interface HomeServicesProps {
    title: string;
    children: ReactNode;
}

function HomeServices(props: HomeServicesProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.body}>
                    <div className={classes.illustration}>
                        <img src={ClusterIllustration} alt="" />
                    </div>

                    <div className={classes.copy}>
                        <H2>{props.title}</H2>
                        <Lead size="small">{props.children}</Lead>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default memo(HomeServices);
