/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Button from "../common/Button";
import ContactUs from "../common/ContactUs";
import Customers from "../common/Customers";
import Footer from "../common/Footer";
import Intro from "../common/Intro";
import Page from "../common/Page";
import Meta from "../common/Meta";
import CompanySection from "../home/HomeCompany";
import HomeHero from "../home/HomeHero";
import ProductsSection from "../home/HomeProducts";
import ServicesSection from "../home/HomeServices";
import {H1} from "../common/typography/Headings";

const IndexJa = () => (
    <Page lang="ja" title="AI + 言語" path="/ja/">
        <Meta name="description">
            アティリカは東京とオスロに所在する自然言語処理およびAIを専門とする会社です。
            世界中の企業は弊社の技術とディープラーニングソリューションを活用しています。
        </Meta>
        <HomeHero>
            <H1>AI + 言語</H1>
        </HomeHero>
        <Intro>
            <p>
                アティリカは東京とオスロに所在する自然言語処理およびAIを専門とする会社です。
                世界中の企業は弊社の技術とディープラーニングソリューションを活用しています。
            </p>
        </Intro>
        <ServicesSection title="サービス">
            <p>
                私たちはイノベーションパートナーとしてお客様と密接に連携を取りながら前へ進もうとしています。
                検索、自然言語処理、機械学習においての難し課題に取り組み、斬新なソリューションを開発することに心がけています。
                世界中でそれぞれの業界のリーダ的存在となる企業はミッションクリティカルなサービスおよび商品にわれわれを
                信頼していただき、弊社の技術を採用していただいています。
            </p>
            <p>
                <Button to="/ja/services/" readMore>
                    more
                </Button>
            </p>
        </ServicesSection>
        <ProductsSection title="製品">
            <p>
                アティリカは東アジアと西洋の各言語において自然言語処理の製品スイートを開発しています。
                形態素解析、クエリサジェスト、キーフレーズ抽出、固有表現抽出などの製品があります。
            </p>
            <p>
                Kuromojiは業界スタンダードとなっている弊社の日本語形態素解析器です。Apache
                Luceneおよび Apache
                Solrの日本語サポートを可能としています。Elasticsearchにも活用されています。
            </p>
            <p>
                <Button to="/ja/products/" readMore>
                    more
                </Button>
            </p>
        </ProductsSection>
        <CompanySection title="企業">
            <p>
                私たちは東京とオスロに所在する経験豊富のソフトウェアエンジニアの小さいチームです。
                アティリカは弊社が開発した独自の技術製品とコンサルティングサービスを提供しております。
            </p>
            <p>
                <Button to="/ja/company/" white readMore>
                    more
                </Button>
            </p>
        </CompanySection>
        <Customers title="弊社のお客様" />
        <ContactUs title="Let’s get to work">
            <p>プロジェクトのご相談を承っています。ご連絡をお待ちしております。</p>
        </ContactUs>
        <Footer lang="ja" />
    </Page>
);

export default memo(IndexJa);
