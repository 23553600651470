/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import AnnotationIllustration from "../assets/images/illustrations/home-annotation.svg";
import UI from "../common/UI";
import Container from "../common/Container";
import {H2} from "../common/typography/Headings";
import {Lead} from "../common/typography/Text";

const useStyles = createUseStyles({
    root: {
        overflow: "hidden",
        position: "relative",
        padding: "3.5rem 0 4rem",
        [UI.media.only("xs")]: {
            padding: "1.6rem 0 0"
        },
        [UI.media.gte("lg")]: {
            padding: "4rem 0 5rem"
        }
    },
    body: {
        [UI.media.only("xs")]: {
            display: "block"
        },
        [UI.media.gte("sm")]: {
            display: "flex",
            width: "100%"
        },
        [UI.media.gte("lg")]: {
            display: "flex",
            width: "83.3333%",
            margin: "auto"
        }
    },
    copy: {
        [UI.media.gte("sm")]: {
            flexBasis: "66.666%"
        },
        [UI.media.gte("lg")]: {
            flexBasis: "50%",
            paddingRight: ".875rem"
        }
    },
    illustration: {
        [UI.media.only("xs")]: {
            overflow: "hidden",
            height: 132,
            paddingTop: "3rem"
        },
        [UI.media.between("sm", "md")]: {
            width: "40%",
            bottom: "4rem",
            position: "absolute",
            right: "-4rem"
        },
        [UI.media.gte("lg")]: {
            alignItems: "center",
            display: "flex",
            flexBasis: "50%",
            paddingLeft: ".875rem"
        },
        "& img": {
            width: "100%",
            height: "auto"
        }
    }
});

interface HomeProductsProps {
    title: string;
    children: ReactNode;
}

function HomeProducts(props: HomeProductsProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <div className={classes.body}>
                    <div className={classes.copy}>
                        <H2>{props.title}</H2>
                        <Lead size="small">{props.children}</Lead>
                    </div>

                    <div className={classes.illustration}>
                        <img src={AnnotationIllustration} alt="" />
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default memo(HomeProducts);
