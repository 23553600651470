/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import NeuralNetworkIllustration from "../assets/images/illustrations/home-neural-network.svg";
import Hero from "../common/hero/Hero";
import HeroMask from "../common/hero/HeroMask";
import HeroIllustration from "../common/hero/HeroIllustration";
import HeroContent from "../common/hero/HeroContent";
import HeroBackground from "../common/hero/HeroBackground";
import {createUseStyles} from "react-jss";
import {StaticImage} from "gatsby-plugin-image";

interface HomeHeroProps {
    children: ReactNode;
}

const useStyles = createUseStyles({
    background: {
        objectPosition: "center center",
        objectFit: "cover",
        width: "100%",
        height: "100%"
    }
});

function HomeHero({children}: HomeHeroProps) {
    const classes = useStyles();

    return (
        <Hero>
            <HeroBackground opacity={0.56}>
                <StaticImage
                    className={classes.background}
                    src="../assets/raw-photos/typing-hands-1.jpg"
                    alt=""
                    layout="constrained"
                    width={2500}
                />
            </HeroBackground>
            <HeroMask variant="light" />
            <HeroIllustration image={NeuralNetworkIllustration} size="cover" />
            <HeroContent>{children}</HeroContent>
        </Hero>
    );
}

export default memo(HomeHero);
