/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo, ReactNode} from "react";
import {createUseStyles} from "react-jss";
import UI from "../common/UI";
import Container from "../common/Container";
import {H2} from "../common/typography/Headings";
import {Lead} from "../common/typography/Text";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = createUseStyles({
    root: {
        position: "relative"
    },
    content: {
        position: "relative",
        color: UI.colors.white.toCSS(),
        padding: "10rem 0",
        zIndex: 2,
        [UI.media.only("xs")]: {
            padding: "3rem 0"
        },
        [UI.media.only("sm")]: {
            padding: "6rem 0"
        }
    },
    mask: {
        backgroundImage: `linear-gradient(
            to top,
            ${UI.colors.blue.toCSS()},
            ${UI.colors.brightBlue.toCSS()} 43%,
            rgba(100, 201, 252, .56)
        )`,
        height: "100%",
        position: "absolute",
        mixBlendMode: "multiply",
        width: "100%"
    },
    backgroundImage: {
        height: "100%",
        objectFit: "cover",
        left: 0,
        top: 0,
        position: "absolute",
        opacity: 0.57
    }
});

interface HomeCompanyProps {
    title: string;
    children: ReactNode;
}

function HomeCompany(props: HomeCompanyProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <StaticImage
                className={classes.backgroundImage}
                src="../assets/raw-photos/startup-lab.jpg"
                alt=""
                layout="constrained"
                width={2500}
            />
            <div className={classes.mask} />
            <div className={classes.content}>
                <Container>
                    <H2>{props.title}</H2>
                    <Lead size="large">{props.children}</Lead>
                </Container>
            </div>
        </div>
    );
}

export default memo(HomeCompany);
